import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import ijoon from "./modules/ijoon";
import station from "./modules/station";

const allowDevice = ["1F0776D", "20D2473"];

export default createStore({
  state: {
    stationName: ["sample"],
    totalStation: {},

    targetStation: "",

    stations: {
      sample: {
        acc_x: "0",
        acc_y: "0",
        acc_z: "0",
        dir: "0",
        velocity: "0.0",
        temper: "0",
        hum: "0",
        press: "0",
        remain: "0",
        statusValue: "0",
        movement: "0",
        lineOne: "0",
        lineTwo: "0",
        lineThree: "0",
        breakerStatus: false,
        inclineStatus: "0",
        x: 127.29902904077046,
        y: 36.3553421292538,
        time: "2021년 10월 13일 19:26",
      },
    },

    isSpinner: false,
    isDetail: false,
    changeSaftyValue: {},
    initDevice: {},
    deviceStyleEffect: {},
    statusSpinner: false,
    myDownlinkBtn: {
      myturn: false,
      youturn: false,
    },
    detailDevice: "", // detail page 새로 고침 오류
  },
  mutations: {
    // mqtt에서 새로운 디바이스 들어오면 리스트에 정보 추가 시키기
    setStationValue: function (state, data) {
      if (state.stationName.includes("sample")) {
        state.stationName.pop("sample");
        // delete state.stations['sample']
      }
      if (!state.stationName.includes(data.station)) {
        state.stationName.push(data.station);
      }
      state.stations[data.station] = data.stationValue;

      if (state.targetStation === "sample") {
        state.targetStation = data.station;
      }

      state.stationName = state.stationName.filter((item) =>
        allowDevice.includes(item)
      );

      if (!allowDevice.includes(state.targetStation)) {
        state.targetStation = "20D2473";
      }

      for (let i of Object.keys(state.stations)) {
        if (!allowDevice.includes(i)) {
          delete state.stations[i];
        }
      }
      console.log(state.stations);
    },
    // 다운링크 설정 했으니 디바이스 상태 값 변화
    requestedDeviceDownLinkStatus: function (state, data) {
      state.stations[data.device].statusValue = data.status;
    },
    // 하나의 디바이스 다운링크 상태 가져오기
    setDeviceOneDownLinkStatus: function (state, data) {
      state.stations[data.device].statusValue = data.statusValue;
    },
    // 로딩 스피너
    loaddingSpinner: function (state, value) {
      state.isSpinner = value;
    },

    setIsDetail: function (state, value) {
      state.isDetail = value;
    },
    setChangeSaftyValue: function (state, value) {
      state.changeSaftyValue = value;
    },
    setInitDevice: function (state, value) {
      state.initDevice = value;
    },
    setDeviceStyleEffect: function (state, value) {
      state.deviceStyleEffect = value;
    },
    setDeviceStyleEffectClose: function (state, data) {
      state.deviceStyleEffect = data;
    },

    setStatusSpinner: function (state, value) {
      state.statusSpinner = value;
    },
    setMyDownlinkBtn: function (state, value) {
      state.myDownlinkBtn = value;
    },
    setTargetStation: function (state, station) {
      state.targetStation = station;
    },
    setTotalStation: function (state, data) {
      state.totalStation = data;
      // if (Object.keys(state.totalStation).includes(data.station)) {
      //   state.totalStation[data.station].push(data.stationValue)
      // } else {
      //   state.totalStation[data.station] = [data.stationValue]
      // }
    },
    // 서버에서 받은 디바이스 중에서 로컬에 저장한 디바이스들이 없으면 삭제해야함
    removeStations: function (state, data) {
      for (let i = 0; i < data.length; i++) {
        delete state.stations[data[i]];
      }
    },
    // 새로고침 디바이스
    setDetailDevice: function (state, data) {
      state.detailDevice = data;
      console.log(data);
    },
  },
  actions: {},
  getters: {
    getSelectStation: (state) => (key) => {
      return state.stations[key];
    },
  },
  modules: {
    ijoon,
    station,
  },
  plugins: [
    createPersistedState({
      // paths: ["station"],
    }),
  ],
});
