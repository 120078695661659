<template>
  <div class="block2">
    <p>차단기 제어</p>
  </div>
  <div class="block2con">
    <p><span >원격</span></p>
    <p><span >수동</span></p>
  </div>
</template>

<script>
export default {
  setup () {
    

    return {}
  }
}
</script>

<style  scoped>

</style>