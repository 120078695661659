<template>
  <div class="contents">
    <div class="con">
      <ul>
        <li>
          <p>기지국 상태</p>
          <!-- 나중에 고칠 부분 -->
          <span
            :class="{ effect: changedValue?.safeStatus }"
            v-if="state?.safeStatus"
            class="danger"
            >점검 요망</span
          >
          <span :class="{ effect: changedValue?.safeStatus }" v-else>정상</span>
        </li>
        <li>
          <p>전력선 1</p>
          <span
            :class="{
              effect: changedValue?.lineOne,
              danger: state?.lineOne === '정상' ? false : true,
            }"
            >{{ state?.lineOne }}</span
          >
        </li>
        <li>
          <p>전력선 2</p>
          <span
            :class="{
              effect: changedValue?.lineTwo,
              danger: state?.lineTwo === '정상' ? false : true,
            }"
            >{{ state?.lineTwo }}</span
          >
        </li>
        <li>
          <p>전력선 3</p>
          <span
            :class="{
              effect: changedValue?.lineThree,
              danger: state?.lineThree === '정상' ? false : true,
            }"
            >{{ state?.lineThree }}</span
          >
        </li>
        <li>
          <p>풍향</p>
          <span :class="{ effect: changedValue?.dir }">{{ state?.dir }}</span>
        </li>
        <li>
          <p>풍속</p>
          <span :class="{ effect: changedValue?.velocity }"
            >{{ state?.velocity }} m/s</span
          >
        </li>
        <li>
          <p>온도</p>
          <span :class="{ effect: changedValue?.temper }"
            >{{ state?.temper }} ℃</span
          >
        </li>
        <li>
          <p>습도</p>
          <span :class="{ effect: changedValue?.hum }">{{ state?.hum }} %</span>
        </li>
        <li>
          <p>압력</p>
          <span :class="{ effect: changedValue?.press }"
            >{{ state?.press }} hPa</span
          >
        </li>
        <li>
          <p>움직임 감지</p>
          <span
            v-if="!state?.movement"
            :class="{ effect: changedValue?.movement }"
            >없음</span
          >
          <span
            v-else-if="state?.movement === 1"
            :class="{ effect: changedValue?.movement }"
            class="danger"
            >1회 감지</span
          >
          <span
            v-else-if="state?.movement === 2"
            :class="{ effect: changedValue?.movement }"
            class="danger"
            >움직임 보통</span
          >
          <span
            v-else-if="state?.movement === 3"
            :class="{ effect: changedValue?.movement }"
            class="danger"
            >움직임 많음</span
          >
          <span v-else :class="{ effect: changedValue?.movement }"
            >움직임 많음</span
          >
        </li>
        <li>
          <p>화재감지</p>
          <span
            :class="{
              effect: changedValue?.fire,
              danger: isFire === '정상' ? false : true,
            }"
            >{{ isFire }}
          </span>
        </li>
        <li>
          <p>도난감지</p>
          <span
            :class="{
              effect: changedValue?.theft,
              danger: isTheft === '정상' ? false : true,
            }"
            >{{ isTheft }}
          </span>
        </li>
      </ul>
      <p class="reception_time">수신 받은 시간 : {{ state?.time }}</p>
    </div>
    <!--con 끝-->

    <!-- <div class="conimg">
      <img src="@/assets/station.png" alt="" width="300" />
      <div class="mt-3 d-flex flex-column align-items-center">
        <p class="accx" :class="{ effect: changedValue?.acc_x }">acc_x : {{ state?.acc_x }}° </p>
        <p class="acc" :class="{ effect: changedValue?.acc_y }">acc_y : {{ state?.acc_y }}° </p>
      </div>
    </div> -->
    <!--conimg 끝-->
  </div>
</template>

<script>
import { computed, reactive, watch } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    station: {
      type: String,
    },
  },
  emits: [],
  setup(props, { emit }) {
    const store = useStore();
    const changedValue = reactive({
      acc_x: false,
      acc_y: false,
      acc_z: false,
      dir: false,
      velocity: false,
      temper: false,
      hum: false,
      press: false,
      movement: false,
      lineOne: false,
      lineTwo: false,
      lineThree: false,
      breakerStatus: false,
      inclineStatus: false,
    });

    // const state =   computed(() => store.getters.getSelectStation(props.station))
    const state = computed(() => store.state?.stations[props.station]);
    const styleEffect = computed(() => store.state?.deviceStyleEffect);

    // 변경된 데이터 확인(반짝 거림)
    watch(styleEffect, () => {
      if (styleEffect.value.value === false) {
        Object.keys(changedValue).forEach((key) => {
          changedValue[key] = false;
        });
      } else {
        if (styleEffect.value.station === props.station) {
          styleEffect.value.changedValue?.forEach((key) => {
            changedValue[key] = true;
          });
          setTimeout(() => {
            store.commit("setDeviceStyleEffectClose", {
              changedValue: styleEffect.value.changedValue,
              value: false,
              station: "",
            });
          }, 800);
        } else {
          store.commit("setDeviceStyleEffectClose", {
            changedValue: styleEffect.value.changedValue,
            value: false,
            station: "",
          });
        }
      }
    });

    const isFire = computed(() =>
      store.state.stations[props.station]?.fire ? "감지됨" : "정상"
    );
    const isTheft = computed(() =>
      store.state.stations[props.station]?.theft ? "감지됨" : "정상"
    );

    return {
      state,
      changedValue,
      isFire,
      isTheft,
    };
  },
};
</script>

<style scoped>
@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}

.effect {
  animation: blink-effect 0.5s step-end infinite;
  color: crimson;
}

.accx {
  margin-bottom: 10px;
  font-size: 17px;
}

.reception_time {
  font-size: 0.8em;
}

.danger {
  color: crimson;
}
</style>
