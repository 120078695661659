<template>
  <div class="main" v-if="!detailDevice">
    <div class="map">
      <!-- <img class="staion_img"  :src="imgScr" @click="searchDevice" v-if="!isDevice" alt=""> -->
      <Map
        :station="willGostaion"
        :initMarker="willMakeMarker"
        @goStationInfo="goStationInfo"
      />
    </div>
    <div class="device" v-show="true">
      <div class="wrap">
        <div class="header"></div>
        <StationMosquitto
          @goMarker="goMarker"
          @makeInitMarker="makeInitMarker"
        />
      </div>
      <!--wrap 끝-->
      <div class="footer"></div>
      <!--footer 끝-->
    </div>
  </div>
  <!-- <Detail v-else /> -->
  <Spinner :loading="isSpinner" />
</template>

<script>
import StationMosquitto from "./StationMosquitto.vue";
import Spinner from "@/views/components/Spinner.vue";
import Map from "./Map/Map.vue";
import { computed, ref } from "@vue/reactivity";
import stationImg from "@/assets/station.png";
import { useStore } from "vuex";
import { watch } from "@vue/runtime-core";
// import Detail from './Detail/Detail.vue'

export default {
  components: {
    StationMosquitto,
    Map,
    Spinner,
    // Detail
  },

  setup() {
    const test = false;
    const isDevice = ref(false);
    const willGostaion = ref({});
    const willMakeMarker = ref({});
    const store = useStore();
    const isSpinner = computed(() => store.state.isSpinner);
    const targetDevice = computed(() => store.state.station.targetStation);

    const detailDevice = computed(() => store.state.detailDevice);

    watch(targetDevice, () => {
      goMarker(targetDevice.value);
    });

    function searchDevice() {
      isDevice.value = true;
    }

    function exitSearchDevice() {
      isDevice.value = false;
    }

    const imgScr = stationImg;

    // 내가 고른 장치로 이동하기
    function goMarker(station) {
      willGostaion.value = store.state.stations[station];
    }

    // 처음 들어온 장치 마커 표시
    function makeInitMarker(station) {
      willMakeMarker.value = station;
    }

    // 마커 클릭시 사이드 바 나오기
    function goStationInfo(station) {
      if (!isDevice.value) {
        searchDevice();
      }
      willGostaion.value = store.state.stations[station];
    }

    return {
      isDevice,
      searchDevice,
      imgScr,
      goMarker,
      willGostaion,
      makeInitMarker,
      willMakeMarker,
      exitSearchDevice,
      goStationInfo,
      isSpinner,
      detailDevice,
    };
  },
};
</script>

<style scoped>
.device {
  width: 30vw;
  min-width: 450px;
  height: 100vh;
  background-color: white;
  overflow: auto;
}

.staion_img {
  margin: 30px;
  width: 50px;
  z-index: 2;
  float: left;
  position: absolute;
  cursor: pointer;
}

.staion_img:hover {
  transform: scale(1.2);
}

.main {
  display: flex;
}

.map {
  width: 75vw;
  height: 100vh;
}

.btn {
  float: right;
}

.close {
  font-size: 1.2em;
}

.slide-fade-enter-active {
  transition: all 0.6s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-leave-active {
  transition: all 0.6s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .main {
    display: inline-block;
  }
  .map {
    width: 100%;
    height: 70vh;
  }
  .device {
    height: 120vh;
    width: 100vw;
  }
}
</style>
