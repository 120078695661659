<template>
  <div class="select">
    <ul>
      <li>
        <i class="device_info fas fa-info-circle" @click="goDetail"></i>
      </li>
      <li>
        <select
          name=""
          v-model="selectedStation"
          @change="goMarker"
          id="selectcon"
        >
          <option v-for="station in stations" :key="station">
            {{ station }}
          </option>
        </select>
      </li>
      <li>
        <div v-loading="statusSpinner">
          <i
            @click="deviceDownLinkSetting"
            v-if="!isStatusValue"
            id="exchange"
            class="fas fa-exchange-alt"
          >
            <span id="exchangeText">(차단기 상태 변경)</span>
          </i>
          <i id="exchangeBlock" v-else class="fas fa-ban">
            <span id="exchangeText">(차단기 상태 변경 대기중)</span>
          </i>
        </div>
      </li>
    </ul>
  </div>
  <!--selcet 끝-->
  <div class="outputContainer">
    <div class="output">
      <p>출력 상태</p>
    </div>
    <!-- <p class="reception_time" :style="isSystemDown ? 'color: crimson' : 'color: black' ">{{ selectedTime }} </p> -->
  </div>
  <div>
    <Station :station="selectedStation" />
  </div>
  <BreakerStatus :station="selectedStation" />
  <BreakerControl />
</template>

<script>
import Station from "./Station.vue";
import { useStore } from "vuex";
import { reactive, ref, computed, watch } from "vue";
import BreakerControl from "./Breaker/BreakerControl.vue";
import BreakerStatus from "./Breaker/BreakerStatus.vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";

export default {
  components: {
    Station,
    BreakerControl,
    BreakerStatus,
  },

  emits: ["goMarker", "makeInitMarker", "changeAcc"],
  setup(props, { emit }) {
    const allowDevice = ["1F0776D", "20D2473"];

    const store = useStore();
    const stations = computed(() => store.state.stationName);
    const selectedStation = ref(
      store.state.station.targetStation === ""
        ? "sample"
        : !allowDevice.includes(store.state.station.targetStation)
        ? "20D2473"
        : store.state.station.targetStation
    ); // 내가 보던 것이였으면 보던 device로

    const styleEffect = reactive({
      station: "",
      value: false,
      changedValue: [],
    });
    const router = useRouter();
    const statusSpinner = computed(() => store.state.statusSpinner);
    const clickTargetStation = computed(
      () => store.state.station.targetStation
    );

    watch(clickTargetStation, () => {
      selectedStation.value = clickTargetStation.value;
    });

    store.commit("setIsDetail", false);

    const isStatusValue = computed(() => {
      return store.state.stations[selectedStation.value]?.statusValue;
    });

    const myDownlinkBtn = computed(() => store.state.myDownlinkBtn);

    // 단말 전송 (다운 링크)
    function deviceDownLinkSetting() {
      store.commit("setStatusSpinner", false);
      store.commit("setMyDownlinkBtn", { myturn: true, youturn: false });
      // 토글로 해야하는데 url을 false, true 이것으로 적히기 때문에 아래와 같이 정의 함
      const data = {
        deviceId: selectedStation.value,
        status: isStatusValue.value ? 0 : 1,
        // status: computed(() => {
        //   if (isStatusValue.value) {
        //     return 0
        //   } else {
        //     return 1
        //   }
        // }).value
      };
      store
        .dispatch("ijoon/requestDeviceDownLinkSetting", data)
        .then((res) => {
          store.commit("requestedDeviceDownLinkStatus", {
            device: selectedStation.value,
            status: data.status,
          });
        })
        .catch(() => {
          alert("에러");
        });
      setTimeout(() => {
        store.commit("setStatusSpinner", false);
      }, 500);
    }

    function goMarker() {
      emit("goMarker", selectedStation.value); // 내가 보고 싶은 장치로 이동하기
      store.commit("station/setTargetStation", selectedStation.value);
    }

    // Detail로 이동
    function goDetail() {
      if (selectedStation.value === "sample") {
        ElMessage({
          showClose: true,
          message: "들어온 데이터가 없습니다.",
          type: "error",
        });
        return;
      }

      store.commit("loaddingSpinner", true);
      // store.commit("setDetailDevice", selectedStation.value);
      router.push({
        name: "Detail",
        params: {
          device: selectedStation.value,
        },
      });

      const detailDevice = computed(() => store.state.detailDevice);

      window.onhashchange = function (event) {
        event.preventDefault();
        if (!detailDevice.value) {
          store.commit("setDetailDevice", selectedStation.value);
        }
      };
    }

    // // 고른 device time
    // const selectedTime = computed(() => store.state.stations[selectedStation.value].time)

    // // 30분 동안 반응 없으면 빨간문구 (분 단위만 보기)
    // const isSystemDown = ref(false)
    // const devicePing = computed(() => store.state.stations[selectedStation.value].sortTime)
    // const currentTime = new Date().getTime()

    // isSystemDown.value =   currentTime - devicePing.value >= 30 ? true : false

    // watch(selectedStation, () => {
    //   const devicePing = computed(() => store.state.stations[selectedStation.value].sortTime)
    //   const currentTime = new Date().getTime()

    //   isSystemDown.value = currentTime - devicePing.value >= 30 ? true : false

    //   setTimeout(() => {
    //     const devicePing = computed(() => store.state.stations[selectedStation.value].sortTime)
    //     const currentTime = new Date().getTime()

    //     isSystemDown.value = currentTime - devicePing.value >= 30 ? true : false
    //   }, 1800000)

    // })

    return {
      stations,
      selectedStation,
      styleEffect,
      isStatusValue,
      deviceDownLinkSetting,
      statusSpinner,
      goMarker,
      goDetail,
      myDownlinkBtn,
    };
  },
};
</script>

<style scoped>
li {
  display: inline-block;
  list-style: none;
}

.loading {
  display: inline-block;
  margin-right: 1em;
}

#exchange {
  color: rgb(116, 184, 48);
  font-size: 1em;
  cursor: pointer;
  margin-left: 1em;
}

#exchange:hover {
  color: crimson;
}

#exchangeText {
  margin-left: 10px;
  font-size: 15px;
}

#exchangeBlock {
  color: crimson;
  font-size: 20px;
  border-radius: 3px;
  margin-left: 1em;
  /* cursor: pointer; */
}
/* 
#exchangeBlock:hover {
  margin-top: 39px;
  font-size: 25px;
} */

.device_info {
  font-size: 25px;
  color: rgb(157, 157, 163);
  cursor: pointer;
}

.device_info:hover {
  color: rgb(33, 33, 36);
}

@media screen and (max-width: 768px) {
  li {
    display: inline-block;
    list-style: none;
  }
}

@media screen and (max-width: 380px) {
  #exchangeBlock {
    color: crimson;
    font-size: 1em;
  }
  ul {
    padding-left: 8px;
  }
}
.outputContainer {
}

.reception_time {
  display: inline-block;
  text-align: center;
  width: 15em;
  font-size: 0.9em;
  font-weight: bolder;
}
</style>
