<template>
  <div class="detailRoot">
    <div v-if="isMobile" class="d-flex title">
      <img
        v-if="!currentDevice.safeStatus"
        class="Stationimg"
        :src="stationSrc"
        alt=""
      />
      <img v-else class="Stationimg" :src="alertSrc" alt="" />
      <h1 style="border-bottom: 2px solid #005fad">
        Device : {{ deviceName }}
      </h1>
      <div class="mapBtn d-flex" @click="goMap">
        <i class="mapBtnIcon far fa-arrow-alt-circle-left"></i>
        <span class="mapBtnName">뒤로 가기</span>
      </div>
    </div>

    <div v-else class="d-flex title" style="margin-top: 7px">
      <img
        v-if="!currentDevice.safeStatus"
        class="Stationimg"
        :src="stationSrc"
        alt=""
      />
      <img v-else class="Stationimg" :src="alertSrc" alt="" />
      <h1
        style="
          border-bottom: 2px solid #005fad;
          font-size: 20px;
          margin-top: 8px;
        "
      >
        Device : {{ deviceName }}
      </h1>
      <div class="mapBtn d-flex" @click="goMap">
        <i class="mapBtnIcon far fa-arrow-alt-circle-left"></i>
        <span class="mapBtnName">뒤로 가기</span>
      </div>
    </div>

    <div v-if="isMobile">
      <div id="container">
        <el-table
          :data="tableData"
          height="100%"
          style="width: 100%"
          border
          highlight-current-row
        >
          <el-table-column
            sortable
            prop="sortTime"
            align="center"
            label="Date"
            width="200"
            fixed
          >
            <template #default="scope">
              <p>{{ scope.row.time.slice(0, 19) }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="기지국 상태" width="120">
            <template #default="scope">
              <p v-if="scope.row.safeStatus" class="danger">점검 요망</p>
              <p v-else>정상</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="차단기 상태" width="110">
            <template #default="scope">
              <p v-if="scope.row.breakerStatus" class="danger">Off</p>
              <p v-else>On</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="남북 방향" width="110">
            <template #default="scope">
              <p>{{ scope.row.acc_y }}°</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="동서 방향" width="110">
            <template #default="scope">
              <p>{{ scope.row.acc_x }}°</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="전력선 1" width="110">
            <template #default="scope">
              <p
                :class="{ danger: scope.row.lineOne === '정상' ? false : true }"
              >
                {{ scope.row.lineOne }}
              </p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="전력선 2" width="110">
            <template #default="scope">
              <p
                :class="{ danger: scope.row.lineTwo === '정상' ? false : true }"
              >
                {{ scope.row.lineTwo }}
              </p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="전력선 3" width="110">
            <template #default="scope">
              <p
                :class="{
                  danger: scope.row.lineThree === '정상' ? false : true,
                }"
              >
                {{ scope.row.lineThree }}
              </p>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="dir" label="풍향" width="110" />
          <el-table-column
            align="center"
            prop="velocity"
            label="풍속 (m/s)"
            width="110"
          />
          <el-table-column
            align="center"
            prop="temper"
            label="온도 (℃)"
            width="110"
          />
          <el-table-column
            align="center"
            prop="hum"
            label="습도 (%)"
            width="110"
          />
          <el-table-column
            align="center"
            prop="press"
            label="압력 (hPa)"
            width="110"
          />
          <el-table-column align="center" label="움직임" width="110">
            <template #default="scope">
              <p v-if="!scope.row.movement">없음</p>
              <p class="danger" v-else-if="scope.row.movement === 1">
                1회 감지
              </p>
              <p class="danger" v-else-if="scope.row.movement === 2">
                움직임 보통
              </p>
              <p class="danger" v-else-if="scope.row.movement === 3">
                움직임 많음
              </p>
              <p class="danger" v-else>움직임 많음</p>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="화재"
            width="110"
          >
            <template #default="scope">
              <p v-if="scope.row.fire" class="danger">점검 요망</p>
              <p v-else>정상</p>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="도난"
            width="110"
          >
            <template #default="scope">
              <p v-if="scope.row.theft" class="danger">점검 요망</p>
              <p v-else>정상</p>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-else>
      <MobileTable :tableData="tableData" />
    </div>
  </div>
  <div class="mobile"></div>
  <Spinner :loading="isSpinner" />
</template>

<script>
import Spinner from "../components/Spinner.vue";
import MobileTable from "../MobileTable/MobileTable.vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { computed, ref } from "@vue/reactivity";
import stationSrc from "@/assets/station.png";
import alertSrc from "@/assets/alert.png";
import _ from "lodash";
import { onUnmounted, watch } from "@vue/runtime-core";

export default {
  components: {
    Spinner,
    MobileTable,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const deviceName = route.params.device;
    // const deviceName = computed(() => store.state.detailDevice);
    const tableData = ref([]);
    const currentDevice = ref([]);
    const isSpinner = computed(() => store.state.isSpinner);
    const newTableData = computed(() => store.state.totalStation);

    watch(newTableData, () => {
      if (newTableData.value.station === deviceName.value) {
        tableData.value.unshift(newTableData.value.stationValue);
        currentDevice.value = newTableData.value.stationValue;
      }
    });

    // 데이터 파싱
    function dataParsing(reciveMessage) {
      const message = reciveMessage.message;
      const device = reciveMessage.device;

      const state = {
        acc_x: "0",
        acc_y: "0",
        acc_z: "0",
        dir: "0",
        velocity: "0.0",
        temper: "0",
        hum: "0",
        press: "0",
        statusValue: "0",
        movement: "0",
        lineOne: "0",
        lineTwo: "0",
        lineThree: "0",
        breakerStatus: false,
        inclineStatus: false,
        x: 0.0,
        y: 0.0,
        fire: false,
        theft: false,
        time: "",
        safeStatus: true,
      };

      state.acc_x = message.slice(0, 3);
      state.acc_y = message.slice(3, 6);
      state.acc_z = message.slice(6, 9);
      state.dir = message.slice(9, 11);
      state.velocity = message.slice(11, 14);
      state.temper = message.slice(14, 16);
      state.hum = message.slice(16, 18);
      state.press = message.slice(18, 21);
      const movement = parseInt(message.slice(21, 22), 16)
        .toString(2)
        .padStart(4, "0");
      const wireConnect = parseInt(message.slice(22, 23), 16)
        .toString(2)
        .padStart(4, "0"); // 16진수 2진수로 변환
      const transferStatus = parseInt(message.slice(23, 24), 16)
        .toString(2)
        .padStart(4, "0");
      state.inclineStatus = transferStatus[1] === "1" ? true : false;

      // 두번째 자리 : 차단기 상태 및 전선 상테
      state.breakerStatus = wireConnect[0] === "1" ? true : false;
      state.lineThree = wireConnect[1] === "1" ? "끊어짐" : "정상";
      state.lineTwo = wireConnect[2] === "1" ? "끊어짐" : "정상";
      state.lineOne = wireConnect[3] === "1" ? "끊어짐" : "정상";

      // 세번째 자리 : 움직임
      if (movement[2] === "1" && movement[3] === "1") {
        state.movement = 3;
      } else if (movement[2] === "1") {
        state.movement = 2;
      } else if (movement[3] === "1") {
        state.movement = 1;
      } else if (movement[3] === "0") {
        state.movement = 0;
      }

      state.fire = movement[1] === "1" ? true : false;
      state.theft = movement[0] === "1" ? true : false;

      for (let value in state) {
        if (value === "dir") {
          const dirValue = parseInt(state[value], 16) * 2;
          if (dirValue === 0) {
            state[value] = "북풍";
          } else if (0 < dirValue && dirValue < 90) {
            state[value] = "북동풍";
          } else if (dirValue === 90) {
            state[value] = "동풍";
          } else if (90 < dirValue && dirValue < 180) {
            state[value] = "남동풍";
          } else if (dirValue === 180) {
            state[value] = "남풍";
          } else if (180 < dirValue && dirValue < 270) {
            state[value] = "남서풍";
          } else if (dirValue === 270) {
            state[value] = "서풍";
          } else {
            state[value] = "북서풍";
          }
        } else if (value === "velocity") {
          state[value] = parseInt(state[value], 16) / 10;
          state[value] = state[value].toFixed(1);
        } else if (value === "temper") {
          state[value] = parseInt(state[value], 16);
          if (state.temper & 0x80) {
            state.temper = state.temper & 0x7f;
            state.temper = -state.temper;
          }
        } else if (
          value === "acc_x" ||
          value === "acc_y" ||
          value === "acc_z"
        ) {
          state[value] = parseInt(state[value], 16);
          state[value] = (state[value] * 0.09).toFixed(1);
        } else if (value === "hum" || value === "press") {
          state[value] = parseInt(state[value], 16);
        }
      }

      // 안전 상태
      // if (state.inclineStatus || state.fire || state.theft ||
      //   state.breakerStatus) {
      //   state['safeStatus'] = true
      // } else {
      //   state['safeStatus'] = false
      // }

      if (state.fire || state.theft || state.breakerStatus) {
        state["safeStatus"] = true;
      } else {
        state["safeStatus"] = false;
      }

      // 풍속에 따른 기울기 확인
      if (Number(state.velocity) >= 10) {
        if (Number(state.acc_x > 30) || Number(state.acc_y > 30)) {
          state["safeStatus"] = true;
        }
      } else {
        if (state.inclineStatus) {
          state["safeStatus"] = true;
        }
      }

      return state;
    }

    // ijoon 서버 요청 보내기
    async function requestTableValue(device) {
      const res = await store.dispatch("ijoon/requestDeviceTableValue", {
        deviceId: device,
      });

      if (res.data[device] === undefined) {
        router.push({
          name: "NotFound",
        });
        store.commit("loaddingSpinner", false);
      } else {
        res.data[device].forEach((reciveData) => {
          const reciveMessage = {
            message: reciveData.data,
            device: reciveData.device,
          };

          const state = dataParsing(reciveMessage);

          const today = new Date(reciveData.ping);
          const year = today.getFullYear(); // 년도
          const month = today.getMonth() + 1; // 월
          const date = today.getDate(); // 날짜
          const hours = today.getHours(); // 시
          const minutes =
            String(today.getMinutes()).length === 2
              ? today.getMinutes()
              : "0" + today.getMinutes(); // 분

          state.time = `${year}년 ${month}월 ${date}일 ${hours}:${minutes}`;
          state["sortTime"] = reciveData.ping; // 나중에 테이블 순서

          const data = {
            station: device,
            stationValue: state,
          };

          tableData.value.push(state);
          currentDevice.value = tableData.value[0];
        });

        store.commit("loaddingSpinner", false);
      }
    }

    // 모바일 구현
    const isMobile = ref(true);

    if (window.innerWidth < 520) {
      isMobile.value = false;
    }

    window.addEventListener("resize", () => {
      console.log(window.innerWidth);
      if (window.innerWidth < 520) {
        isMobile.value = false;
      } else {
        isMobile.value = true;
      }
    });

    // 지도로 가기
    function goMap() {
      store.commit("loaddingSpinner", true);
      setTimeout(() => {
        router.push({
          name: "Main",
        });
        // store.commit("setDetailDevice", "");
        store.commit("loaddingSpinner", false);
      }, 0);
    }

    requestTableValue(deviceName);

    return {
      deviceName,
      goMap,
      isSpinner,
      tableData,
      stationSrc,
      alertSrc,
      currentDevice,
      isMobile,
    };
  },
};
</script>

<style scoped>
@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}

.effect {
  animation: blink-effect 0.5s step-end infinite;
  color: crimson;
}

.title {
  padding: 50px 0px 0px 40px;
}

.Stationimg {
  width: 2.6em;
  height: 2.8em;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
}

.mapBtn {
  cursor: pointer;
  margin-top: auto;
  margin-left: 40px;
  margin-bottom: 16px;
}

.mapBtn:hover {
  opacity: 0.5;
}

.mapBtnIcon {
  font-size: 1.5em;
}

.mapBtnName {
  cursor: pointer;
  font-weight: bolder;
  margin-top: auto;
  font-size: 1em;
  margin-left: 10px;
}

#container {
  height: 85vh;
  padding: 1em;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

p {
  margin-top: 6px;
  margin-bottom: 6px;
}

.danger {
  color: crimson;
}

.detailRoot {
  height: 100vh;
}

.mobile {
  width: 0;
  height: 0;
}

@media screen and (max-width: 768px) {
  .title {
    padding: 30px 0px 0px 30px;
  }
  .mapBtn {
    margin-top: 10px;
  }
}
</style>
