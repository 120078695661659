<template>
  <div class="boxcontainer">
    <div class="box" @click="goHome">
      <div class="circle">
        <h1 class="title">404</h1>
        <h2 class="subtitle">Not Found</h2>
      </div>
    </div>
  </div>
</template>

<script>
import stationSrc from '@/assets/station.png' 
import { useRouter } from 'vue-router'

export default {

  setup () {

    const router = useRouter()

    const station = stationSrc
    
    function goHome () {
      router.push({
        name: 'Main'
      })
    }

    return {
      station, goHome
    }

  }

}
</script>

<style scoped>

.boxcontainer {
  max-width: 80%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: auto;
}


.img {
  width: 3em;
  height: 3.5em;
  margin: auto;
}

.box {
  margin: auto;
  cursor: pointer;
}

.box:hover {
  opacity: 0.5;
}

.circle {
  border: 20px solid black;
  border-radius: 100%;
  padding: 8em;
}

.title {
  text-align: center;
  font-size: 10em;
}

.subtitle {
  text-align: center;
  font-size: 3em;
}

</style>
