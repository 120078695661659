const allowDevice = ["1F0776D", "20D2473"];

export default {
  namespaced: true,
  state: {
    targetStation: "",
  },
  mutations: {
    setTargetStation: function (state, station) {
      console.log(station);

      if (!allowDevice.includes(station)) {
        state.targetStation = "20D2473";
        localStorage.setItem("targetDevice", "20D2473");
      } else {
        state.targetStation = station;
        localStorage.setItem("targetDevice", station);
      }
    },
  },
  actions: {},
  modules: {},
};
