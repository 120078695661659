<!-- @format -->

<template>
  <div id="appContainer">
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
    <Spinner :loading="loading" />
  </div>
  <router-view />
</template>

<script>
import mqtt from "mqtt";
import { useStore } from "vuex";
import { ref, reactive, computed } from "vue";
import { ElNotification } from "element-plus";
import Spinner from "@/views/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  setup() {
    const store = useStore();
    const styleEffect = reactive({
      station: "",
      value: false,
      changedValue: [],
    });

    const allowDevice = ["1F0776D", "20D2473"];
    // const allowDevice = ["1F0776D", "20D2473", "2221A05", "22219A7"];

    const loading = computed(() => store.state.isSpinner);
    const myDownlinkBtn = computed(() => store.state.myDownlinkBtn);
    const stationList = computed(() => store.state.stationName); // vuex에 있는 devices
    const stationListValue = computed(() => store.state.stations); // vuex에 있는 devices value

    const MQTTURL = "wss://" + window.location.host + "/ws";

    const client = mqtt.connect("wss://ijoon.co.kr:443", {
      username: "ijoon",
      password: "cadasic",
      clientId: "mqttjs_" + Math.random().toString(16).substr(2, 8),
    });

    // const client = mqtt.connect('ws://127.0.0.1:8883', {
    //   // username: 'ijoon',
    //   // password: 'cadasic',
    //   // clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8),
    // })

    // const client = mqtt.connect('ws://127.0.0.1:8883')

    client.stream.on("error", (err) => {
      console.log("error", err);
    });

    function oneSubscribe() {
      const qos = 0;
      const topic = `sigfox/+`;
      client.subscribe(topic, { qos }, (err, res) => {
        if (err) {
          console.log(topic, "연결 실패", err);
        } else {
          console.log(topic, "연결 성공");
        }
      });

      const notify = "sigfox/+/notify";
      client.subscribe(notify, { qos }, (err, res) => {
        if (err) {
          console.log(notify, "연결 실패", err);
        } else {
          console.log(notify, "연결 성공");
        }
      });
    }

    async function mqttResDownLinkStatus(device) {
      const status = await store.dispatch(
        "ijoon/requestOneDeviceDownLinkStatus",
        device
      );
      const data = {
        device: device,
        statusValue: status.data.value,
      };
      store.commit("setDeviceOneDownLinkStatus", data);
    }

    // // 데이터 파싱 함수로 정의 (device, data) -> async / await 필요 없음 (기다리는게 없기 때문)
    function dataParsing(reciveMessage) {
      const message = reciveMessage.message;
      const device = reciveMessage.device;

      const state = {
        acc_x: "0",
        acc_y: "0",
        acc_z: "0",
        dir: "0",
        velocity: "0.0",
        temper: "0",
        hum: "0",
        press: "0",
        statusValue: "0",
        movement: "0",
        lineOne: "0",
        lineTwo: "0",
        lineThree: "0",
        breakerStatus: false,
        inclineStatus: false,
        x: 0.0,
        y: 0.0,
        fire: false,
        theft: false,
        time: "",
        safeStatus: false,
      };

      state.acc_x = message.slice(0, 3);
      state.acc_y = message.slice(3, 6);
      state.acc_z = message.slice(6, 9);
      state.dir = message.slice(9, 11);
      state.velocity = message.slice(11, 14);
      state.temper = message.slice(14, 16);
      state.hum = message.slice(16, 18);
      state.press = message.slice(18, 21);
      const movement = parseInt(message.slice(21, 22), 16)
        .toString(2)
        .padStart(4, "0");
      const wireConnect = parseInt(message.slice(22, 23), 16)
        .toString(2)
        .padStart(4, "0"); // 16진수 2진수로 변환
      const transferStatus = parseInt(message.slice(23, 24), 16)
        .toString(2)
        .padStart(4, "0");

      state.inclineStatus = transferStatus[1] === "1" ? true : false;

      // 두번째 자리 : 차단기 상태 및 전선 상테
      state.breakerStatus = wireConnect[0] === "1" ? true : false;
      state.lineThree = wireConnect[1] === "1" ? "끊어짐" : "정상";
      state.lineTwo = wireConnect[2] === "1" ? "끊어짐" : "정상";
      state.lineOne = wireConnect[3] === "1" ? "끊어짐" : "정상";

      // 세번째 자리 : 움직임
      if (movement[2] === "1" && movement[3] === "1") {
        state.movement = 3;
      } else if (movement[2] === "1") {
        state.movement = 2;
      } else if (movement[3] === "1") {
        state.movement = 1;
      } else if (movement[3] === "0") {
        state.movement = 0;
      }

      state.fire = movement[1] === "1" ? true : false;
      state.theft = movement[0] === "1" ? true : false;

      for (let value in state) {
        if (value === "dir") {
          const dirValue = parseInt(state[value], 16) * 2;
          if (dirValue === 0) {
            state[value] = "북풍";
          } else if (0 < dirValue && dirValue < 90) {
            state[value] = "북동풍";
          } else if (dirValue === 90) {
            state[value] = "동풍";
          } else if (90 < dirValue && dirValue < 180) {
            state[value] = "남동풍";
          } else if (dirValue === 180) {
            state[value] = "남풍";
          } else if (180 < dirValue && dirValue < 270) {
            state[value] = "남서풍";
          } else if (dirValue === 270) {
            state[value] = "서풍";
          } else {
            state[value] = "북서풍";
          }
        } else if (value === "velocity") {
          state[value] = parseInt(state[value], 16) / 10;
          state[value] = state[value].toFixed(1);
        } else if (value === "temper") {
          state[value] = parseInt(state[value], 16);
          if (state.temper & 0x80) {
            state.temper = state.temper & 0x7f;
            state.temper = -state.temper;
          }
        } else if (
          value === "acc_x" ||
          value === "acc_y" ||
          value === "acc_z"
        ) {
          state[value] = parseInt(state[value], 16);
          state[value] = (state[value] * 0.09).toFixed(1);
        } else if (value === "hum" || value === "press") {
          state[value] = parseInt(state[value], 16);
        }
      }

      // 임시 좌표 만들기
      if (device === "20D2473") {
        state["x"] = "127.17869519099773";
        state["y"] = "37.57803441013677";
      } else if (device === "2221A05") {
        state["x"] = "127.19150965167638";
        state["y"] = "37.58110922643622";
      } else if (device === "1F0776D") {
        state["x"] = "127.18448265230857";
        state["y"] = "37.37786877274466 ";
      } else {
        if (stationList.value.includes(device)) {
          state["x"] = stationListValue.value[device].x;
          state["y"] = stationListValue.value[device].y;
        } else {
          if (stationList.value.includes("sample")) {
            state["x"] = `127.1711186321`;
            state["y"] = `37.5711176141`;
          } else {
            state["x"] = `127.17${stationList.value.length}86321`;
            state["y"] = `37.57${stationList.value.length}76141`;
          }
        }
      }

      // 안전 상태
      if (state.fire || state.theft || state.breakerStatus) {
        state["safeStatus"] = true;
      } else {
        state["safeStatus"] = false;
      }

      // 풍속에 따른 기울기 확인
      if (Number(state.velocity) >= 10) {
        if (Number(state.acc_x > 30) || Number(state.acc_y > 30)) {
          state["safeStatus"] = true;
        }
      } else {
        if (state.inclineStatus) {
          state["safeStatus"] = true;
        }
      }

      return state;
    }

    client.on("connect", () => {
      oneSubscribe();
    });

    // window.addEventListener("beforeunload", function (event) { client.end() })

    const targetDevice = computed(() => store.state.targetStation);

    client.on("message", (topic, res) => {
      clientMessage(topic, res);
    });

    async function clientMessage(topic, res) {
      // 다운 링크 조절
      if (topic.includes("notify")) {
        const message = JSON.parse(res);
        const device = topic.split("/")[1];
        const data = {
          device,
          statusValue: message,
        };
        const confirm = myDownlinkBtn.value.myturn;
        // 지금 보고 있는 device랑 topic으로 들어온 device가 같을 때만 알림뜨고 로딩스피너
        if (device === targetDevice.value) {
          store.commit("setStatusSpinner", true);
          if (myDownlinkBtn.value.myturn) {
            store.commit("setMyDownlinkBtn", { myturn: false, youturn: false });
          } else {
            store.commit("setMyDownlinkBtn", { myturn: false, youturn: true });
            ElNotification({
              title: `DownLink Status ( ${device} )`,
              message:
                "다른 유저가 downlink 조작을 조작하거나 downlink가 완료 됐습니다.",
              type: "info",
              position: "top-left",
            });
          }
          setTimeout(() => {
            store.commit("setStatusSpinner", false);
          }, 500);
          // 다르면 로딩, 알림 없음
        } else {
          store.commit("setMyDownlinkBtn", { myturn: false, youturn: false });
        }

        store.commit("setDeviceOneDownLinkStatus", data);
      } else {
        // 데이터 들어오는 것
        let reciveMessage = {};
        try {
          reciveMessage = JSON.parse(res);
        } catch {
          alert("서버 에러");
          return;
        }
        const message = reciveMessage.data;
        const device = reciveMessage.device;

        console.log(message, device);

        // if (device === "20D2473") {
        //   return;
        // }
        if (!allowDevice.includes(device)) {
          return;
        }

        const state = dataParsing({ device, message });

        const today = new Date();
        const year = today.getFullYear(); // 년도
        const month = today.getMonth() + 1; // 월
        const date = today.getDate(); // 날짜
        const hours = today.getHours(); // 시
        const minutes =
          String(today.getMinutes()).length === 2
            ? today.getMinutes()
            : "0" + today.getMinutes(); // 분

        state.time = `${year}년 ${month}월 ${date}일 ${hours}:${minutes}`;
        state["sortTime"] = new Date().getTime(); // 나중에 테이블 순서

        // 데이터가 처음 들어온 값이라면 반짝이는 것 처리 안한다.
        try {
          const storedStation = store.state.stations[device];
          const changedValue = [];

          // 바뀐 값 넣기
          Object.keys(storedStation).forEach((key) => {
            if (storedStation[key] !== state[key]) {
              changedValue.push(key);
            }
          });

          styleEffect.value = true;
          styleEffect.station = device;
          styleEffect.changedValue = changedValue;
          if (changedValue.length) {
            const deviceValue = {
              device: device,
              state: state,
            };
            store.commit("setChangeSaftyValue", deviceValue); //
          }
          store.commit("setDeviceStyleEffect", styleEffect); // 처음이 아닌 곳
        } catch {
          const initStation = {
            state: state,
            device: device,
          };

          store.commit("setInitDevice", initStation); // 나 처음 들어온 장치다~
        }

        const data = {
          station: device,
          stationValue: state,
        };
        store.commit("setStationValue", data);
        store.commit("setTotalStation", data);
        mqttResDownLinkStatus(device);
        // if (selectedStation.value === 'sample') {
        //   selectedStation.value = store.state.stationName[0]
        // }
      }
    }

    // 모든 디바이스의 다운링크 상태 확인
    function deviceDownLinkStatus() {
      store.commit("setStatusSpinner", true);
      store
        .dispatch("ijoon/requestDevieceDownLinkStatus")
        .then((res) => {
          const downLinkList = res.data.downlink;

          for (let i = 0; i < stationList.value.length; i++) {
            const device = stationList.value[i];
            const data = {
              device,
              statusValue: 0,
            };
            if (Object.keys(downLinkList).includes(device)) {
              data.statusValue = 1;
            }

            store.commit("setDeviceOneDownLinkStatus", data);
          }
          store.commit("setStatusSpinner", false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    //
    // 처음 입장 할때 ijoon 서버에 저장된 장치들 가져오기

    async function requestAllDeviceList() {
      store.commit("loaddingSpinner", true);
      store.commit("setStatusSpinner", true); // 다운링크 로딩 스피너
      const res = await store.dispatch("ijoon/requestDeviceList");
      // 타겟을 마추기 위해
      // 저장된 타겟이 20D2473이면 일단 sample이라고 놓기 => 원래 타겟이 20D2473이면 안되는데 오류 제거
      // 그런 다음 저장된 타겟이 서버에도 있다면 또는 없다면으로 나누기

      // if (store.state.station.targetStation === "20D2473") {
      //   store.commit("station/setTargetStation", "sample");
      // }

      if (Object.keys(res.data).includes(store.state.station.targetStation)) {
        store.commit(
          "station/setTargetStation",
          store.state.station.targetStation
        );
      } else {
        // 단말기 두개만 사용하기
        const serverRequestDeviceList = Object.keys(res.data).filter((item) => {
          if (allowDevice.includes(item)) {
            return true;
          }
          return false;
        });
        // if (serverRequestDeviceList.includes("20D2473")) {
        //   serverRequestDeviceList.pop("20D2473");
        // }
        if (serverRequestDeviceList.length) {
          if (localStorage.getItem("targetDevice")) {
            store.commit(
              "station/setTargetStation",
              localStorage.getItem("targetDevice")
            );
          } else {
            store.commit(
              "station/setTargetStation",
              serverRequestDeviceList[0]
            );
          }
        }
      }

      // 모든 디바이스 최신 값 가져오기
      for (const device in res.data) {
        // if (device === "20D2473") {
        //   continue;
        // }

        if (!allowDevice.includes(device)) {
          continue;
        }

        // 오래 된 것 지우기
        // const nowTime = new Date().getTime()
        // const chai = nowTime - res.data[device].ping

        // console.log(Math.floor(chai / (1000 * 60 * 60)), device) // 시간

        // if (Math.floor(chai / (1000 * 60 * 60)) > 3) {
        //   continue
        // }
        // console.log(Math.floor(chai / (1000 * 60 * 60 * 24))) // 날짜

        const message = res.data[device].data;
        const state = dataParsing({ device, message });

        const today = new Date(res.data[device].ping);
        const year = today.getFullYear(); // 년도
        const month = today.getMonth() + 1; // 월
        const date = today.getDate(); // 날짜
        const hours = today.getHours(); // 시
        const minutes =
          String(today.getMinutes()).length === 2
            ? today.getMinutes()
            : "0" + today.getMinutes(); // 분

        state.time = `${year}년 ${month}월 ${date}일 ${hours}:${minutes}`;
        state["sortTime"] = res.data[device].ping; // 나중에 테이블 순서

        const data = {
          station: device,
          stationValue: state,
        };

        store.commit("setStationValue", data); // 장치 store에 추가하기
      }

      // 보던 타겟이 삭제 된다면
      // const targetDevice = store.state.station.targetStation;
      // if (!Object.keys(res.data).includes(targetDevice)) {
      //   const deviceList = Object.keys(res.data);
      //   if (deviceList.length > 1) {
      //     if (deviceList[0] !== "20D2473") {
      //       store.commit("station/setTargetStation", Object.keys(res.data)[0]);
      //     } else {
      //       store.commit("station/setTargetStation", Object.keys(res.data)[1]);
      //     }
      //   }
      //   // 서버에 없는 디바이스 삭제 (디바이스의 대한 정보 localstorage에 저장하기 떄문)
      //   store.commit("removeStations", deviceList);
      // }

      deviceDownLinkStatus();
      setTimeout(() => {
        store.commit("loaddingSpinner", false);
        store.commit("setStatusSpinner", false);
      }, 500);
    }

    requestAllDeviceList();

    // window.onbeforeunload = function(event) {
    //   setTimeout(() => {
    //     store.commit('setDetailDevice', '')
    //   }, 0)
    // };

    const detailDevice = computed(() => store.state.detailDevice);

    window.onhashchange = function (event) {
      event.preventDefault();
      if (detailDevice.value) {
        store.commit("setDetailDevice", "");
      }
    };

    return {
      loading,
    };
  },
};
</script>

<style scoped></style>
