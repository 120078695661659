<template>
  <div id="mapContainer" ref="mapContainer"></div>
</template>

<script>
import {
  computed,
  createElementBlock,
  onMounted,
  watch,
} from "@vue/runtime-core";
import { ref } from "vue";
import { useStore } from "vuex";
import stationImg from "@/assets/station.png";
import myalertImg from "@/assets/alert.png";

export default {
  props: {
    station: {
      type: [Object, String],
    },
    initMarker: {
      type: [Object],
    },
  },
  emits: ["goStationInfo"],

  setup(props, { emit }) {
    const mapContainer = ref(null);
    const map = ref("");
    const store = useStore();
    const stations = computed(() => store.state.stations);
    const overlaies = ref([]);

    onMounted(() => {
      if (window.kakao && window.kakao.maps) {
        initMap();
      } else {
        const script = document.createElement("script"); // 이거 주석 있어야함
        /* global kakao */ script.onload = () => kakao.maps.load(initMap);
        script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=62f8e983061dfd222635658f8891e99d&autoload=false`;
        document.head.appendChild(script);
      }
    });

    const station = computed(() => props.station);

    // 내가 고른 장치로 이동하기 (지도 이동, select로 고른 것)
    watch(station, () => {
      const move = new kakao.maps.LatLng(station.value.y, station.value.x);
      map.value.panTo(move);
    });

    const willMakeMarker = computed(() => store.state.initDevice);

    // 처음 들어온 값 마커로 표시하기
    watch(willMakeMarker, () => {
      const markerPosition = new kakao.maps.LatLng(
        willMakeMarker.value.state.y,
        willMakeMarker.value.state.x
      );

      const stationDevice = willMakeMarker.value.device; // 변수로 따로 나눠주지 않으면 goStation에서 device가 계속 자동으로 변한다 => ref('')로 선언 되었기 때문
      let markerOverlay;
      const container = document.createElement("div");
      const divDevice = document.createElement("p");
      const spanX = document.createElement("div");
      const spanY = document.createElement("div");
      const img = document.createElement("img");
      const rightContainer = document.createElement("div");

      // 오버레이 이벤트
      container.addEventListener("click", () => {
        goStation(stationDevice);
      });
      container.style = `display: flex`;
      divDevice.innerText = `${stationDevice}`;
      divDevice.style = `text-align: center; font-size: 0.8em; font-weight: bolder; margin-bottom: 7px`;
      img.src = `${stationImg}`;
      img.className = "marker_img";
      spanX.className = "acc";
      spanY.className = "acc";
      img.style = "margin-left: 8px";
      spanX.style = `text-align: center; margin-top: 5px`;
      spanY.style =
        "text-align: right; margin-top:auto; margin-right: 8px; margin-bottom: auto ";
      spanX.innerText = `${willMakeMarker.value.state.acc_x}`;
      spanY.innerText = `${willMakeMarker.value.state.acc_y}`;

      if (
        willMakeMarker.value.state.theft ||
        willMakeMarker.value.state.fire ||
        willMakeMarker.value.state.breakerStatus
      ) {
        img.className = "marker_img alert_img";
        img.src = `${myalertImg}`;
      }

      if (Number(willMakeMarker.value.state.velocity >= 10)) {
        if (
          Number(willMakeMarker.value.state.acc_x >= 30) ||
          Number(willMakeMarker.value.state.acc_y >= 30)
        ) {
          img.className = "marker_img alert_img";
          img.src = `${myalertImg}`;
        }
      } else {
        if (willMakeMarker.value.state.inclineStatus) {
          img.className = "marker_img alert_img";
          img.src = `${myalertImg}`;
        }
      }

      container.appendChild(spanY);
      rightContainer.appendChild(divDevice);
      rightContainer.appendChild(img);
      rightContainer.appendChild(spanX);
      container.appendChild(rightContainer);
      // container.appendChild(divDevice)
      // container.appendChild(spanX)
      // container.appendChild(img)

      // 드레그 막기
      container.addEventListener("dragstart", function (e) {
        map.value.setDraggable(false);
      });

      container.addEventListener("dragend", function (e) {
        map.value.setDraggable(true);
      });

      markerOverlay = new kakao.maps.CustomOverlay({
        position: markerPosition,
        content: container,
        xAnchor: 0.7,
        yAnchor: 0.65,
      });

      overlaies.value.push({
        station: stationDevice,
        markerOverlay: markerOverlay,
        position: markerPosition,
      });

      // 커스텀

      markerOverlay.setMap(map.value);
    });

    // 처음 지도 표시 될때 가지고 있는 장치들 마커로 표시하기
    function makeMarkerFn() {
      Object.keys(stations.value).forEach((station) => {
        if (station !== "sample") {
          const markerPosition = new kakao.maps.LatLng(
            stations.value[station].y,
            stations.value[station].x
          );
          const container = document.createElement("div");
          const divDevice = document.createElement("p");
          const spanX = document.createElement("div");
          const spanY = document.createElement("div");
          const img = document.createElement("img");
          const rightContainer = document.createElement("div");

          container.addEventListener("click", () => {
            goStation(station);
          });
          container.style = `display: flex`;
          divDevice.innerText = `${station}`;
          divDevice.style = `text-align: center; font-size: 0.8em; font-weight: bolder; margin-bottom: 7px`;
          img.src = `${stationImg}`;
          img.className = "marker_img";
          spanX.className = "acc";
          spanY.className = "acc";
          img.style = "margin-left: 8px";
          spanX.style = `text-align: center; margin-top: 5px`;
          spanY.style =
            "text-align: right; margin-top:auto; margin-right: 8px; margin-bottom: auto ";
          spanX.innerText = `${stations.value[station].acc_x}`;
          spanY.innerText = `${stations.value[station].acc_y}`;

          // 위험 감지 사진 변경하기
          if (
            stations.value[station].theft ||
            stations.value[station].fire ||
            stations.value[station].breakerStatus
          ) {
            img.className = "marker_img alert_img";
            img.src = `${myalertImg}`;
          }

          if (Number(stations.value[station].velocity) >= 10) {
            if (
              Number(stations.value[station].acc_x) >= 30 ||
              Number(stations.value[station].acc_y) >= 30
            ) {
              img.className = "marker_img alert_img";
              img.src = `${myalertImg}`;
            }
          } else {
            if (stations.value[station].inclineStatus) {
              img.className = "marker_img alert_img";
              img.src = `${myalertImg}`;
            }
          }

          container.appendChild(spanY);
          rightContainer.appendChild(divDevice);
          rightContainer.appendChild(img);
          rightContainer.appendChild(spanX);
          container.appendChild(rightContainer);

          // container.appendChild(divDevice)
          // container.appendChild(spanX)
          // container.appendChild(img)

          // 드레그 막기
          container.addEventListener("dragstart", function (e) {
            map.value.setDraggable(false);
          });

          container.addEventListener("dragend", function (e) {
            map.value.setDraggable(true);
          });

          const markerOverlay = new kakao.maps.CustomOverlay({
            position: markerPosition,
            content: container,
            xAnchor: 0.7,
            yAnchor: 0.65,
          });

          // 커스텀
          overlaies.value.push({
            station: station,
            markerOverlay: markerOverlay,
            position: markerPosition,
          });

          markerOverlay.setMap(map.value);
        }
      });
    }

    // 이동 생성하기
    const targetStation = computed(() => store.state.station.targetStation);
    function initMap() {
      let center; // 다른 곳 갔다 왔을 때 내가 보고 있던 장치를 중심으로
      if (!targetStation.value) {
        center = new kakao.maps.LatLng(37.577614, 127.178632);
      } else {
        const x = stations.value[targetStation.value]?.x;
        const y = stations.value[targetStation.value]?.y;
        center = new kakao.maps.LatLng(y, x);
      }
      const options = {
        center: center,
        level: 3,
      };
      map.value = new kakao.maps.Map(mapContainer.value, options);
      makeMarkerFn();
    }

    // 마커 클릭 했을 때 사이드 바 나오기
    function goStation(station) {
      emit("goStationInfo", station); // 지도 중심으로 이동
      const move = new kakao.maps.LatLng(
        stations.value[station].y,
        stations.value[station].x
      );
      store.commit("station/setTargetStation", station);
      map.value.panTo(move);
    }

    // x, y 변경
    const changeDevice = computed(() => store.state.changeSaftyValue.device);
    const accChangeValue = computed(
      () => store.state.stations[changeDevice.value]
    );

    watch(accChangeValue, () => {
      console.log(accChangeValue.value);
      console.log(overlaies.value, "오버레이 값들");
      // 마커 이미지 바꾸기 (오버레이로 대체)
      const container = document.createElement("div");
      const divDevice = document.createElement("p");
      const spanX = document.createElement("div");
      const spanY = document.createElement("div");
      const img = document.createElement("img");
      const rightContainer = document.createElement("div");

      const device = changeDevice.value;
      container.addEventListener("click", () => {
        goStation(device);
      });
      container.style = `display: flex`;
      divDevice.innerText = `${device}`;
      divDevice.style = `text-align: center; font-size: 0.8em; font-weight: bolder; margin-bottom: 7px`;
      img.src = `${stationImg}`;
      img.className = "marker_img";
      spanX.className = "acc";
      spanY.className = "acc";
      img.style = "margin-left: 8px";
      spanX.style = `text-align: center; margin-top: 5px`;
      spanY.style =
        "text-align: right; margin-top:auto; margin-right: 8px; margin-bottom: auto ";
      spanX.innerText = `${accChangeValue.value.acc_x}`;
      spanY.innerText = `${accChangeValue.value.acc_y}`;

      // 드레그 막기
      container.addEventListener("dragstart", function (e) {
        map.value.setDraggable(false);
      });

      container.addEventListener("dragend", function (e) {
        map.value.setDraggable(true);
      });

      overlaies.value.forEach((data) => {
        if (data.station === changeDevice.value) {
          if (
            accChangeValue.value.theft ||
            accChangeValue.value.fire ||
            accChangeValue.value.breakerStatus
          ) {
            img.className = "marker_img alert_img";
            img.src = `${myalertImg}`;
          }

          if (Number(accChangeValue.value.velocity) >= 10) {
            if (
              Number(accChangeValue.value.acc_x) >= 30 ||
              Number(accChangeValue.value.acc_y) >= 30
            ) {
              img.className = "marker_img alert_img";
              img.src = `${myalertImg}`;
            }
          } else {
            if (accChangeValue.value.inclineStatus) {
              img.className = "marker_img alert_img";
              img.src = `${myalertImg}`;
            }
          }

          container.appendChild(spanY);
          rightContainer.appendChild(divDevice);
          rightContainer.appendChild(img);
          rightContainer.appendChild(spanX);
          container.appendChild(rightContainer);
          data.markerOverlay.setContent(container);
        }
      });
    });

    return {
      mapContainer,
    };
  },
};
</script>

<style>
@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}

.acc {
  color: crimson;
  font-weight: bolder;
}

#mapContainer {
  width: 100%;
  height: 100%;
}

.marker_img {
  width: 40px;
  cursor: pointer;
}

.marker_img:hover {
  transform: scale(1.2);
}

.alert_img {
  animation: blink-effect 1s step-end infinite;
  color: crimson;
}
</style>
