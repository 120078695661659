<template>

  <div id="container">
    <el-table :data="tableData" height="100%" style="width: 100%">
      <el-table-column sortable prop="sortTime" align="center" label="Date" width="180">
        <template #default="scope">
          <p> {{ scope.row.time.slice(0, 19) }} </p>
        </template>
      </el-table-column>
      <el-table-column  align="center"  label="기지국 상태" width="100">
      <template #default="scope">
        <p v-if="scope.row.safeStatus" class="danger"> 점검 요망 </p>
        <p v-else> 정상 </p>
      </template>
      </el-table-column>
      <el-table-column  align="left"  label="정보" width="50">
        <template #default="scope">
          <i class="device_info fas fa-info-circle" @click="goDetail(scope.row, scope.$index)"></i>
        </template>
      </el-table-column>
    </el-table>
  </div>
  
  <MobileDetailModal 
    @mobileModal="mobileModal" 
    :open="detailModalOpen" 
    :seletedMobileData="seletedMobileData" 
    :tableIndex="tableIndex"
    :maxIndex="maxIndex"
    @goNextLevel="goNextLevel"
  />
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import MobileDetailModal from './MobileDetailModal.vue'
import { watch } from '@vue/runtime-core'

export default {

  components: {
    MobileDetailModal
  },

  props: {
    tableData: {
      typeof: Array
    }
  },

  setup (props, { emit }) {
    
    const detailModalOpen = ref(false)
    const seletedMobileData = ref({})
    const tableIndex = ref(0)
    const maxIndex = computed(() => props.tableData.length - 1)

    watch(maxIndex, () => {
      tableIndex.value++
    })

    function goDetail (data, index) {
      tableIndex.value = index
      seletedMobileData.value = data
      detailModalOpen.value = true
    }

    function mobileModal () {
      detailModalOpen.value = false
    }

    function goNextLevel (index) {
      seletedMobileData.value = props.tableData[index]
      tableIndex.value = index
    }

    return {
      goDetail, detailModalOpen, seletedMobileData, mobileModal,
      tableIndex, maxIndex, goNextLevel
    }
  }
}
</script>

<style scoped>

#container {
  width: 92vw;
  height: 80vh;
  padding: 1em;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

#select_data {
  font-size: 1rem;
  width: 13em;
  height: 1.8em;
  margin-top: 35px;
  border-radius: 3px;
  position: absolute;
  left: 50px;
  text-align: center;
}

.status_css {
  display: flex;
  justify-content: center;
}

.device_info {
  padding: 5px;
  margin-left: 5px;
}

p {
  margin-top: 6px;
  margin-bottom: 6px;
}


.danger {
  color: crimson;
}

</style>