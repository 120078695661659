import axios from "axios";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    // 하나의 디바이스 다운링크 상태 확인
    requestOneDeviceDownLinkStatus: async function (payload, device) {
      const url = `https://sigfox.ijoon.net/api/ctrl/dl/${device}`;

      // return axios.get(url).then((res) => res.data.value)

      return await axios.get(url);
    },
    // 모든 디바이스 다운링크 상태 확인
    requestDevieceDownLinkStatus: function () {
      const url = ` https://sigfox.ijoon.net/api/ctrl/dl/all`;
      return axios.get(url);
    },
    // 디바이스 다운링크 설정
    requestDeviceDownLinkSetting: function (payload, data) {
      const url = `https://sigfox.ijoon.net/api/ctrl/dl/${data.deviceId}/${data.status}`;
      return axios.post(url);
    },
    requestDevieInfomationg: async function (payload, data) {
      const url = `https://sigfox.ijoon.net/api/ctrl/device/${data.deviceId}`;
      return await axios.get(url);
    },
    requestDeviceList: function (payload, data) {
      const url = `https://sigfox.ijoon.net/api/ctrl/device/all`;
      return axios.get(url);
    },
    requestDeleteDeviceValue: function (payload, data) {
      const url = `https://sigfox.ijoon.net/api/ctrl/device/${data.deviceId}`;
      return axios.delete(url);
    },
    // 테이블 정보
    requestDeviceTableValue: async function (payload, data) {
      const url = `https://sigfox.ijoon.net/api/ctrl/device/${data.deviceId}/100`;
      console.log(data);
      return await axios.get(url);
    },
  },
  modules: {},
};
