<template>

  <el-dialog v-model="centerDialogVisible" @close="handleClose" :title="state.time" width="80%" center>
    <div class="next_level">
      <i :class="{shadow: isClick === 'right' ? true : false }" class="left fas fa-angle-double-left" @click="nextLevel('left')"></i>
      <i :class="{shadow: isClick === 'left' ? true : false }" class="right fas fa-angle-double-right" @click="nextLevel('right')"></i>
    </div>
    <div class="contents_mobile">
      <div class="con_mobile">
        <ul>
          <li>
            <p>기지국 상태</p>
            <!-- 나중에 고칠 부분 -->
            <span  class="danger value" v-if="state.safeStatus">점검 요망</span>
            <span class="value" v-else>정상</span>
          </li>
          <li>
            <p>차단기 상태</p>
            <!-- 나중에 고칠 부분 -->
            <span  class="danger value" v-if="state.breakerStatus">Off</span>
            <span class="value" v-else>On</span>
          </li>
          <li>
            <p>전력선 1</p>
            <span class="value"  :class="{danger: state.lineOne === '정상' ? false : true}">{{ state.lineOne }}</span>
          </li>
          <li>
            <p>전력선 2</p>
            <span class="value"  :class="{danger: state.lineTwo === '정상' ? false : true}">{{ state.lineTwo }}</span>
          </li>
          <li>
            <p >전력선 3</p>
            <span class="value"  :class="{danger: state.lineThree === '정상' ? false : true}">{{ state.lineThree }}</span>
          </li>
          <li>
            <p >풍향</p>
            <span class="value" >{{ state.dir }}</span>
          </li>
          <li>
            <p >풍속</p>
            <span class="value" >{{ state.velocity }} m/s</span>
          </li>
          <li>
            <p >온도</p>
            <span class="value" >{{ state.temper }} ℃</span>
          </li>
          <li>
            <p >습도</p>
            <span class="value" >{{ state.hum }} %</span>
          </li>
          <li>
            <p>압력</p>
            <span class="value" >{{ state.press }} hPa</span>
          </li>
          <li>
            <p >움직임 감지</p>
            <span class="value" v-if="!state.movement">없음</span>
            <span class="danger value" v-else-if="state.movement === 1">1회 감지</span>
            <span class="danger value" v-else-if="state.movement === 2">움직임 보통</span>
            <span class="danger value" v-else-if="state.movement === 3">움직임 많음</span>
            <span class="danger value" v-else>움직임 많음</span>
          </li>
          <li>
            <p >화재감지</p>
            <span class="value" :class="{danger: isFire === '정상' ? false : true}">{{ isFire }} </span>
          </li>
          <li>
            <p >도난감지</p>
            <span class="value" :class="{danger: isTheft === '정상' ? false : true}">{{ isTheft }} </span>
          </li>
        </ul>
      </div>
    </div>
    <div  class="close_btn">
      <el-button type="danger" size="mini" @click="handleClose">Cancel</el-button>
    </div>
  </el-dialog>

</template>

<script>
import { computed, ref, watch } from 'vue'

export default {

  props: {
    open: {
      type: Boolean
    },
    seletedMobileData: {
      type: Object
    },
    tableIndex: {
      type: Number
    },
    maxIndex: {
      type: Number
    }
  },
  emits: ['mobileModal', 'goNextLevel'],

  setup (props, { emit }) {

    
    const centerDialogVisible = computed(() => props.open)


    const state = computed(() => props.seletedMobileData)

    const isClick = ref(false)

    if (props.tableIndex === 0) {
      isClick.value = 'left'
    } else if (props.tableIndex === props.maxIndex) {
      isClick.value = 'right'
    } else {
      isClick.value = false
    }


    const maxIndex = computed(() => props.maxIndex)
    const tableIndex = computed(() => props.tableIndex)

    watch([maxIndex, tableIndex], () => {
      if (tableIndex.value === 0) {
        isClick.value = 'left'
      } else if (tableIndex.value === maxIndex.value) {
        isClick.value = 'right'
      } else {
        isClick.value = false
      }
    })

    const isFire = computed(() => props.seletedMobileData.fire ? '감지됨' : '정상')
    const isTheft = computed(() => props.seletedMobileData.theft ? '감지됨' : '정상')

    function handleClose () {
      emit('mobileModal')
    }

    function nextLevel (value) {
      if (value === 'right') {
        if (!props.tableIndex) {
          return
        } else {
          emit('goNextLevel', props.tableIndex - 1)
        }
      } else {
        if (props.tableIndex === props.maxIndex) {
          return
        } else {
          emit('goNextLevel', props.tableIndex + 1)
        }
      }
    }

    return {
      centerDialogVisible, state, isFire, isTheft, handleClose, nextLevel,
      isClick
    }
  },
}
</script>

<style scoped>

.contents_mobile {
  /*background-color: #ccc;*/
  height: 550px;
  margin-top: 30px;
  display: block;
  content: "";
  clear: both;
}
/*con 설정*/
.con_mobile {
  width: 80%;
  height: 530px;
  margin-top: 25px;
  margin-left: 10%;
  border-radius: 20px;
  border: 1px solid #9fb6c1;
  background-color: #fff;
  position: relative;
  /* float: left; */
}

.con_mobile ul {
  height: 100%;
  width: 100%;
  margin: 0;
}
.con_mobile ul:nth-child(1) {
  /* position: absolute; */
  display: block;
}
.con_mobile ul:nth-child(2) {
  /* position: absolute; */
  display: none;
}
.con_mobile ul li {
  width: 95%;
  height: 30px;
  margin: 10px 0 10px 0;
  /*            background-color: #ccc;*/
  display: block;
  content: "";
  clear: both;
  border-bottom: 1px dotted #9fb6c1;
}
.con_mobile ul li p {
  float: left;
  margin-left: 0px;
}
.con_mobile ul li span {
  float: right;
  margin-right: 15px;
}

.danger {
  color: crimson;
}

.close_btn {
  display: flex;
  justify-content: flex-end;
}

ul {
  padding-left: 20px;
}

.value {
  margin-right: 3px;
}

.next_level {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: bolder;
}

.left, .right {
  margin: 0px 20px;
}

.shadow {
  opacity: 50%;
}

</style>