<template>
  <div class="block">
    <div class="blocktit">
      <p class="">차단기 상태</p>
    </div>
    <!--blocktit 끝-->
    <div class="blockbut d-flex">
      <label class="switch-button">
        <input
          ref="statusBtn"
          :checked="breakerStatus"
          type="checkbox"
          disabled
        />
        <!-- <span @click="breakerClick" class="onoff-switch"></span> -->
        <span class="onoff-switch"></span>
      </label>
      <div>
        <span
          v-if="!breakerStatus"
          :style="breakerStatus ? 'color: #888;' : 'color: #00a550;'"
          >on</span
        >
        <span
          v-else
          :style="!breakerStatus ? 'color: #888;' : 'color: #ed1c24;'"
          >off</span
        >
      </div>
    </div>
    <!--blockbut 끝-->
  </div>
  <!--block 끝-->
  <!-- <div class="blockcon"> -->
  <!-- <p :style="!breakerStatus ? 'color: #888;' : 'color: #00a550;'">on</p>
    <p :style="breakerStatus ? 'color: #888;' : 'color: #ed1c24;'">off</p>
    <p>off/on</p> -->
  <!-- </div> -->
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    station: {
      type: String,
      require: true,
    },
  },

  setup(props, { emit }) {
    const statusBtn = ref(null);
    const store = useStore();
    const breakerStatus = computed(
      () => store.state.stations[props.station]?.breakerStatus
    );

    return {
      breakerStatus,
      statusBtn,
    };
  },
};
</script>

<style scoped></style>
